import { Text, Tooltip } from "@mantine/core";
import { CellContext } from "@tanstack/react-table";

import { BadgeCell } from "@/components/table/cells/badge-cell";
import type { Competency, Screening } from "@/types/screeners";

interface CompetencyCellProps {
  info: CellContext<Screening, Competency>;
}

export const CompetencyCell = ({ info }: CompetencyCellProps) => {
  const competency = info.getValue();
  if (!competency) {
    return null;
  }

  return (
    <Tooltip
      label={
        <Text size="sm" c="black">
          {competency.quote || "No available quote for this competency."}
        </Text>
      }
      multiline
      withArrow
      arrowSize={8}
      w={400}
      color="white"
      className="border border-gray-200 z-[1000]"
    >
      <BadgeCell
        fieldName="competency"
        value={competency.score}
      >{`${competency.score}`}</BadgeCell>
    </Tooltip>
  );
};
