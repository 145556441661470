import { Badge, Text, Tooltip } from "@mantine/core";
import { useMemo } from "react";

import { getBadgeColor } from "@/utils/ui";

interface MultiBadgesCellProps {
  fieldName: string;
  sessionId: string;
  values: string[];
}

export const MultiBadgesCell = ({
  fieldName,
  sessionId,
  values,
}: MultiBadgesCellProps) => {
  const [valuesToRender, overflowValues] = useMemo(() => {
    if (values.length > 3) {
      return [values.slice(0, 3), values.slice(3, values.length + 1)];
    }

    return [values, null];
  }, [values]);
  // Currently this component is only used for gray info badges where value
  // doesn't matter.
  const { background: backgroundColor, text: textColor } = useMemo(
    () => getBadgeColor("", fieldName),
    [fieldName],
  );

  return (
    <>
      {valuesToRender.map((value) => (
        <span className="mr-0.5" key={`${fieldName}_${value}_${sessionId}`}>
          <Badge
            color={backgroundColor}
            size="md"
            style={{
              minWidth: 72,
              padding: "2px 9px",
            }}
            variant="filled"
          >
            <Text
              c={textColor}
              fw={700}
              fz={10}
              className="tracking-wide"
              size="xs"
            >
              {value}
            </Text>
          </Badge>
        </span>
      ))}
      {overflowValues && overflowValues.length > 0 && (
        <Tooltip
          arrowSize={12}
          c="black"
          classNames={{
            tooltip: "drop-shadow-[0_0_6px_rgba(0,0,0,0.10)]",
          }}
          color="white"
          fz="xs"
          label={overflowValues.join(", ")}
          styles={{
            tooltip: { padding: "12px 18px" },
          }}
          withArrow
        >
          <Badge color={backgroundColor} size="md">
            <Text
              c={textColor}
              fw={700}
              fz={10}
              className="tracking-wide"
              size="xs"
            >
              {`+${overflowValues.length}`}
            </Text>
          </Badge>
        </Tooltip>
      )}
    </>
  );
};
