import { type UseQueryOptions } from "@tanstack/react-query";

import { getScreenerLocationsByScreener } from "@/app/api/screeners";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { AllLocationsResponse } from "@/types/screeners";

const SCREENER_LOCATIONS_QUERY_KEY_BASE = "screener_location";
export const SCREENER_LOCATION_QUERY_KEYS = {
  base: [SCREENER_LOCATIONS_QUERY_KEY_BASE] as const,
  screener: (screenerId: string) => [
    ...SCREENER_LOCATION_QUERY_KEYS.base,
    screenerId,
  ],
};

export const useScreenerLocationsByScreenerQuery = (
  screenerId: string,
  config: Partial<UseQueryOptions<AllLocationsResponse>> = {},
) => {
  return useAuthedQuery({
    queryKey: SCREENER_LOCATION_QUERY_KEYS.screener(screenerId),
    queryFn: () => getScreenerLocationsByScreener(screenerId),
    ...config,
  });
};
