import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Button,
  Flex,
  Image,
  Modal,
  Text,
  TextInput,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import Link from "next/link";
import { useCallback } from "react";

import { userService } from "@/app/api/user";
import { useUserContext } from "@/context/user.context";
import { MFAMethod } from "@/types/user";

interface AccountModalProps {
  onClose: () => void;
  opened: boolean;
}

export const AccountModal = ({ onClose, opened }: AccountModalProps) => {
  const { company, refreshUser, user } = useUserContext();
  const [name, setName] = useInputState(user?.fullName ?? "");

  const handleClose = useCallback(() => {
    onClose();
    setName(user?.fullName ?? "");
  }, [onClose, setName, user]);

  const onSave = useCallback(() => {
    if (user) {
      userService.updateUserName(name).finally(() => {
        refreshUser();
        // Intentionally using onClose rather than the wrapped handleClose to
        // keep state in proper sync
        onClose();
      });
    }
  }, [name, onClose, refreshUser, user]);

  return (
    <Modal
      centered
      keepMounted={false}
      onClose={handleClose}
      opened={opened}
      styles={{
        body: {
          padding: "18px 24px 24px 24px",
        },
        content: {
          flex: "none",
        },
      }}
      withCloseButton={false}
    >
      <Flex align="flex-start" direction="column">
        <Flex align="center" className="w-full" justify="space-between">
          <Text fw={600}>Your account</Text>
          <ActionIcon onClick={handleClose} variant="transparent">
            <Image
              src="/assets/circle-x.svg"
              alt="Close Account Modal"
              h={16}
            />
          </ActionIcon>
        </Flex>
        <TextInput
          className="w-[300px]"
          disabled
          label="Email"
          type="email"
          value={user?.email}
        />
        <TextInput
          className="w-[300px]"
          defaultValue={name}
          label="Full name"
          my={12}
          onChange={setName}
        />
        {company?.mfaEnabled?.includes(MFAMethod.SMS) && (
          <Button
            className="uppercase"
            color="#784DD6"
            component={Link}
            href="/auth/change-phone"
            rightSection={<FontAwesomeIcon icon={faPhone} />}
            p={0}
            variant="transparent"
          >
            Change Phone Number
          </Button>
        )}
        <Button
          className="uppercase"
          color="#784DD6"
          component={Link}
          href="/auth/reset"
          rightSection={<FontAwesomeIcon icon={faLock} />}
          p={0}
          variant="transparent"
        >
          Change Password
        </Button>
        <Flex mt={21}>
          <Button
            className="disabled:bg-[#15BFA3] disabled:opacity-50 disabled:text-white"
            color="#15BFA3"
            disabled={!name || name === user?.fullName}
            mr={9}
            onClick={onSave}
          >
            Save changes
          </Button>
          <Button c="#333333" color="#F2F2F2" onClick={handleClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
