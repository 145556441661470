import { Divider, Text } from "@mantine/core";
import { PropsWithChildren } from "react";

type FilterGroupProps = PropsWithChildren<{
  title?: string;
}>;

export const FilterGroup = ({ children, title }: FilterGroupProps) => {
  return (
    <>
      <Divider my="sm" />
      {title && (
        <Text style={{ marginBottom: "6px" }} fw={600} size="sm">
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </Text>
      )}
      {children}
    </>
  );
};
