import { Text, Tooltip } from "@mantine/core";

interface LocationCellProps {
  location: string[];
}

export const LocationCell = ({ location }: LocationCellProps) => {
  if (!location) {
    return null;
  }

  let displayText;

  if (location.length > 1) {
    displayText = `${location.length} locations`;
  } else {
    displayText = location[0];
  }

  return (
    <Tooltip
      label={
        <Text size="sm" c="black">
          {location.join(" | ")}
        </Text>
      }
      multiline
      withArrow
      arrowSize={8}
      w={400}
      color="white"
    >
      <Text size="sm" c="black">
        {displayText}
      </Text>
    </Tooltip>
  );
};
