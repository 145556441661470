import { DateRangeOptions } from "@/types/date-range-filter";
import { Nullable } from "@/types/utils";
import { ColumnFiltersState } from "@tanstack/react-table";
import { endOfYesterday, startOfToday, startOfYesterday } from "date-fns";
import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns/fp";
import { Dispatch, SetStateAction } from "react";

export const getDateRangeFromOption = (option: Nullable<DateRangeOptions>) => {
  const currentDate = new Date();

  const weekStart = startOfWeek(currentDate);
  const weekEnd = endOfWeek(currentDate);
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);

  const { after, before } = (() => {
    switch (option) {
      case DateRangeOptions.Today:
        return {
          after: startOfToday(),
          before: currentDate,
        };
      case DateRangeOptions.Yesterday:
        return {
          after: startOfYesterday(),
          before: endOfYesterday(),
        };
      case DateRangeOptions.ThisWeek:
        return {
          after: weekStart,
          before: weekEnd,
        };
      case DateRangeOptions.LastWeek: {
        const subOneWeek = subWeeks(1);
        return {
          after: subOneWeek(weekStart),
          before: subOneWeek(weekEnd),
        };
      }
      case DateRangeOptions.ThisMonth:
        return {
          after: monthStart,
          before: monthEnd,
        };
      case DateRangeOptions.LastMonth: {
        const subOneMonth = subMonths(1);
        return {
          after: subOneMonth(monthStart),
          before: subOneMonth(monthEnd),
        };
      }
      case DateRangeOptions.LastThreeDays:
        return {
          after: subDays(3, startOfYesterday()),
          before: endOfYesterday(),
        };
      case DateRangeOptions.LastSevenDays:
        return {
          after: subDays(7, startOfYesterday()),
          before: endOfYesterday(),
        };
      case DateRangeOptions.LastFourteenDays:
        return {
          after: subDays(14, startOfYesterday()),
          before: endOfYesterday(),
        };
      case DateRangeOptions.LastThirtyDays:
        return {
          after: subDays(30, startOfYesterday()),
          before: endOfYesterday(),
        };
      case DateRangeOptions.CustomRange:
      default:
        return {
          after: null,
          before: null,
        };
    }
  })();

  return { after: after?.valueOf(), before: before?.valueOf() };
};

export const addOrReplaceFilterById = <T>(
  id: string,
  value: T,
  setFilters: Dispatch<SetStateAction<ColumnFiltersState>>,
) => {
  setFilters((filters) => {
    const newFilter = { id, value };
    const index = filters.findIndex((filter) => filter.id === id);

    if (index === -1) {
      return [...filters, newFilter];
    }

    return filters.toSpliced(index, 1, newFilter);
  });
};
