import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, TextInput } from "@mantine/core";
import { type ColumnFiltersState } from "@tanstack/react-table";
import { type ChangeEvent, type PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { useUserContext } from "@/context/user.context";

export const FILTER_MENU_TEST_IDS = {
  container: "filter-menu-container",
};

export type FilterMenuProps = PropsWithChildren<{
  clearAllFilters(): void;
  filters: ColumnFiltersState;
  onSearch: (value: string) => void;
  placeHolder?: string;
  rowCount: number;
  searchCountName: string;
  searchValue?: string;
}>;

export const FilterMenu = ({
  children,
  clearAllFilters,
  filters,
  onSearch,
  placeHolder = "Search...",
  rowCount,
  searchCountName,
  searchValue,
}: FilterMenuProps) => {
  const { user } = useUserContext();
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <div
      className={twMerge(
        "border-gray-200",
        "border-r",
        "flex",
        "flex-col",
        "w-56",
        "overflow-auto",
        "p-4",
        user?.internal
          ? "h-[calc(100dvh_-_154px)]"
          : "h-[calc(100dvh_-_106px)]",
      )}
      data-testid={FILTER_MENU_TEST_IDS.container}
    >
      <div className="font-semibold text-sm">
        Showing {rowCount} {searchCountName}
      </div>
      <div className="flex flex-col">
        <Button
          variant="transparent"
          color="#784DD6"
          className="tracking-wider"
          size="compact-xs"
          onClick={clearAllFilters}
          fullWidth
          styles={{
            inner: {
              fontSize: "0.766rem",
              fontWeight: "600",
              justifyContent: "flex-start",
            },
            root: {
              padding: 0,
              visibility: filters.length === 0 ? "hidden" : "visible",
            },
          }}
        >
          CLEAR ALL FILTERS
          <FontAwesomeIcon className="ml-1.5" icon={faXmark} />
        </Button>
      </div>
      <Divider mb="sm" />
      <TextInput
        placeholder={placeHolder}
        onChange={handleSearchChange}
        value={searchValue}
      />
      <div className="flex flex-col">{children}</div>
    </div>
  );
};
