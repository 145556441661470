import { Text } from "@mantine/core";

import { useUserContext } from "@/context/user.context";
import {
  useCompaniesListQuery,
  useCompanyImpersonationMutation,
} from "@/hooks/queries/company.queries";

export const ImpersonationBanner = () => {
  const { user } = useUserContext();
  const { data: companies, isLoading } = useCompaniesListQuery();
  const { mutate: changeCompany } = useCompanyImpersonationMutation();

  return (
    <div
      className={`bg-[#FAE69E] flex flex-row h-[48px] items-center px-[18px] py-[9px] w-full`}
    >
      <Text size="sm">
        {isLoading ? "Loading..." : "Viewing as a member of"}
      </Text>
      {!isLoading && (
        <select
          className="bg-transparent border border-[#666666] font-semibold ml-[8px] rounded-md px-[10px] py-[6px] text-sm"
          defaultValue={user?.companyImpersonation || user?.company.id}
          onChange={(e) => changeCompany(e.target.value)}
        >
          {companies?.map(({ id, name }) => (
            <option className="font-semibold text-xs" key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};
