import { Button, FileInput, Modal, Text } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import {
  useCsvUploadQuery,
  useCsvUploadTranslationQuery,
} from "@/hooks/queries/csv-queries";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";

interface CsvModalProps {
  opened: boolean;
  onClose: () => void;
}

export default function CsvModal({ opened, onClose }: CsvModalProps) {
  const [file, setFile] = useState<File | null>(null);
  const [translationFile, setTranslationFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState({
    status: "",
    message: "",
  });
  const [translationStatus, setTranslationStatus] = useState({
    status: "",
    message: "",
  });

  const { mutate: uploadCsv, isPending: isUploading } = useCsvUploadQuery();
  const { mutate: uploadTranslationCsv, isPending: isUploadingTranslation } =
    useCsvUploadTranslationQuery();

  const handleUploadCsv = () => {
    if (file) {
      uploadCsv(file, {
        onSuccess: () => {
          setUploadStatus({
            status: "success",
            message: "Successfully Uploaded",
          });
        },
        onError: (error) => {
          setUploadStatus({
            status: "error",
            message: error.message,
          });
          console.error("Upload failed", error);
        },
      });
    }
  };

  const handleUploadTranslationCsv = () => {
    if (translationFile) {
      uploadTranslationCsv(translationFile, {
        onSuccess: (data) => {
          setTranslationStatus({
            status: "success",
            message: data.message,
          });
        },
        onError: (error) => {
          setTranslationStatus({
            status: "error",
            message: error.message,
          });
          console.error("Upload failed", error);
        },
      });
    }
  };

  const uploadSuccess = uploadStatus.status === "success";
  const uploadError = uploadStatus.status === "error";
  const translationSuccess = translationStatus.status === "success";
  const translationError = translationStatus.status === "error";

  const resetModalState = useCallback(() => {
    setFile(null);
    setTranslationFile(null);
    setUploadStatus({ status: "", message: "" });
    setTranslationStatus({ status: "", message: "" });
  }, []);

  useEffect(() => {
    if (!opened) {
      resetModalState();
    }
  }, [opened, resetModalState]);

  return (
    <Modal opened={opened} onClose={onClose} title="Upload CSV">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <FileInput
            label="CSV File"
            placeholder="Select a CSV file"
            accept=".csv"
            onChange={(file) => setFile(file)}
          />
          <Button
            disabled={!file}
            onClick={handleUploadCsv}
            loading={isUploading}
            variant={uploadSuccess ? "outline" : "filled"}
            color={uploadSuccess ? "green" : "blue"}
            rightSection={
              uploadSuccess ? <FontAwesomeIcon icon={faCheck} /> : null
            }
          >
            {uploadSuccess ? "Uploaded" : "Upload"}
          </Button>
          <Text c={uploadSuccess ? "green" : "red"} className="text-center">
            {uploadSuccess && uploadStatus.message}
            {uploadError && uploadStatus.message}
          </Text>
        </div>
        <div className="flex flex-col gap-2">
          <FileInput
            label="Translation CSV File"
            placeholder="Select a Translation CSV file"
            accept=".csv"
            onChange={(file) => setTranslationFile(file)}
          />
          <Button
            className="pb-2"
            disabled={!translationFile}
            onClick={handleUploadTranslationCsv}
            loading={isUploadingTranslation}
            variant={translationSuccess ? "outline" : "filled"}
            color={translationSuccess ? "green" : "blue"}
            rightSection={
              translationSuccess ? <FontAwesomeIcon icon={faCheck} /> : null
            }
          >
            {translationSuccess ? "Uploaded" : "Upload"}
          </Button>
          <Text
            c={translationSuccess ? "green" : "red"}
            className="text-center"
          >
            {translationSuccess && translationStatus.message}
            {translationError && translationStatus.message}
          </Text>
        </div>
      </div>
    </Modal>
  );
}
