import { usePrevious } from "@mantine/hooks";
import {
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useUserContext } from "@/context/user.context";
import {
  clearLocalStorageTableState,
  FILTER_VERSION,
  filterVersionLocalStorageKey,
  makeLocalStorageKeyByUser,
} from "@/utils/local-storage";

// Can function as base useState when enabled === false
export const useUserLocalStorageState = <T>(
  baseStorageKey: string,
  initialValue: T,
  enabled = true,
) => {
  const { user } = useUserContext();
  const previousUser = usePrevious(user);
  const storageKey = useMemo(
    () => makeLocalStorageKeyByUser(baseStorageKey, user?.id),
    [baseStorageKey, user],
  );
  const [initialized, setInitialized] = useState(false);
  const [state, setState] = useState<T>(initialValue);
  // Capture all state setter calls and persist them to local storage
  const wrappedSetState = useCallback(
    (valueOrUpdate: SetStateAction<T>) => {
      const newState =
        valueOrUpdate instanceof Function
          ? valueOrUpdate(state)
          : valueOrUpdate;

      localStorage.setItem(storageKey, JSON.stringify(newState));
      setState(newState);
    },
    [state, storageKey],
  );

  // On login/init
  useEffect(() => {
    // If localstorage is not enabled we are already initialized
    if (!initialized && !enabled) {
      return setInitialized(true);
    }

    if (!initialized && user && !previousUser) {
      setInitialized(true);

      const filterVersionStorageKey = filterVersionLocalStorageKey(user.id);
      const filterStorageVersion = localStorage.getItem(
        filterVersionStorageKey,
      );

      // If the user's storage is on an incompatible version we clear ALL search/filter/sort table state.
      if (filterStorageVersion !== FILTER_VERSION) {
        localStorage.setItem(filterVersionStorageKey, FILTER_VERSION);
        return clearLocalStorageTableState(user.id);
      }

      const storedState = localStorage.getItem(storageKey);

      if (enabled && storedState) {
        setState(JSON.parse(storedState));
      }
    }
  }, [enabled, initialized, previousUser, storageKey, user]);

  // On logout
  useEffect(() => {
    if (enabled && previousUser && !user) {
      setState(initialValue);
    }
  }, [enabled, initialValue, previousUser, user]);

  return [state, enabled ? wrappedSetState : setState, initialized] as const;
};
