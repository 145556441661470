import { faCalendar } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Flex, InputBase, Text, useCombobox } from "@mantine/core";
import { useCallback } from "react";

import { DateRangeFilterOption } from "./date-range-filter-option";
import { DateRangeOptions } from "@/types/date-range-filter";
import type { Nullable } from "@/types/utils";

export const DATE_RANGE_FILTER_TEST_IDS = {
  dateRangeFilterDropdown: "date-range-filter-dropdown",
};

export interface DateRangeFilterProps {
  dateRange: Nullable<DateRangeOptions>;
  setDateRange(dateRange: DateRangeOptions): void;
}

const relativeCalendarOptions = [
  {
    helperText: "Today from midnight to now",
    value: DateRangeOptions.Today,
  },
  {
    helperText: "The previous 24-hour day",
    value: DateRangeOptions.Yesterday,
  },
  {
    helperText: "The current calendar week",
    value: DateRangeOptions.ThisWeek,
  },
  {
    helperText: "The previous calendar week",
    value: DateRangeOptions.LastWeek,
  },
  {
    helperText: "The current calendar month",
    value: DateRangeOptions.ThisMonth,
  },
  {
    helperText: "The previous calendar month",
    value: DateRangeOptions.LastMonth,
  },
];

const relativeDaysOptions = [
  {
    helperText: "The previous 3 days before today",
    value: DateRangeOptions.LastThreeDays,
  },
  {
    helperText: "The previous 7 days before today",
    value: DateRangeOptions.LastSevenDays,
  },
  {
    helperText: "The previous 14 days before today",
    value: DateRangeOptions.LastFourteenDays,
  },
  {
    helperText: "The previous 30 days before today",
    value: DateRangeOptions.LastThirtyDays,
  },
  {
    helperText: "Select a custom range",
    value: DateRangeOptions.CustomRange,
  },
];

export const DateRangeFilter = ({
  dateRange,
  setDateRange,
}: DateRangeFilterProps) => {
  const combobox = useCombobox();

  const onOptionClick = useCallback(
    (value: DateRangeOptions) => {
      combobox.toggleDropdown();
      setDateRange(value);
    },
    [combobox, setDateRange],
  );

  return (
    <Combobox dropdownPadding={18} store={combobox} width={475}>
      <Combobox.Target>
        <InputBase
          component="button"
          styles={{
            input: { cursor: "pointer" },
            wrapper: { cursor: "pointer" },
          }}
          leftSection={<FontAwesomeIcon size="sm" icon={faCalendar} />}
          onClick={() => combobox.toggleDropdown()}
          rightSection={<FontAwesomeIcon size="sm" icon={faCaretDown} />}
          type="button"
        >
          <Text c={dateRange ? "black" : "#999999"} size="sm">
            {dateRange ?? "Select range..."}
          </Text>
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown
        data-testid={DATE_RANGE_FILTER_TEST_IDS.dateRangeFilterDropdown}
        style={{ borderRadius: 6, boxShadow: "0px 0px 6px 0px #0000001A" }}
      >
        <Combobox.Options>
          <Flex>
            <Flex direction="column">
              {relativeCalendarOptions.map((option) => (
                <DateRangeFilterOption
                  key={option.value}
                  {...option}
                  onClick={onOptionClick}
                />
              ))}
            </Flex>
            <Flex direction="column">
              {relativeDaysOptions.map((option) => (
                <DateRangeFilterOption
                  key={option.value}
                  {...option}
                  onClick={onOptionClick}
                />
              ))}
            </Flex>
          </Flex>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
