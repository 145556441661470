import { useCallback } from "react";

import { HighlightMenu } from "@/components/highlight-menu";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { useHighlightScreenerSessionQuery } from "@/hooks/queries/screenings.queries";
import type { Screening } from "@/types/screeners";
import type { Nullable } from "@/types/utils";

const ActionMenu = ({ row }: { row: Screening }) => {
  const track = useTrackAnalytics();
  const { mutate } = useHighlightScreenerSessionQuery();

  const handleMenuSelection = useCallback(
    async (value: Nullable<string>) => {
      track("Candidate Highlighted", {
        highlight_color: value || "None",
        screener_session_id: row.sessionId,
      });

      mutate({ id: row.sessionId, value });
    },
    [mutate, row, track],
  );

  return <HighlightMenu onChange={handleMenuSelection} />;
};

export default ActionMenu;
