export enum DateRangeOptions {
  Today = "Today",
  Yesterday = "Yesterday",
  ThisWeek = "This week",
  LastWeek = "Last week",
  ThisMonth = "This month",
  LastMonth = "Last month",
  LastThreeDays = "Last 3 days",
  LastSevenDays = "Last 7 days",
  LastFourteenDays = "Last 14 days",
  LastThirtyDays = "Last 30 days",
  CustomRange = "Custom range",
}

export interface DateRangeFilterOptions {
  helperText: string;
  value: DateRangeOptions;
}
