import { useMutation, UseQueryOptions } from "@tanstack/react-query";

import { userService } from "@/app/api/user";
import { useUserContext } from "@/context/user.context";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import { clearLocalStorageTableState } from "@/utils/local-storage";

const COMPANY_QUERY_KEY_BASE = "company";
const COMPANY_QUERY_KEYS = {
  base: [COMPANY_QUERY_KEY_BASE] as const,
};

interface CompanyListItem {
  id: string;
  name: string;
}

export const useCompaniesListQuery = (
  config: Partial<UseQueryOptions<CompanyListItem[]>> = {},
) => {
  return useAuthedQuery({
    queryFn: () =>
      userService.axiosWithUserAuth
        .get<CompanyListItem[]>("company/cx/companies")
        .then(({ data }) => data),
    queryKey: COMPANY_QUERY_KEYS.base,
    ...config,
  });
};

export const useCompanyImpersonationMutation = () => {
  const { user } = useUserContext();

  return useMutation({
    mutationFn: (companyId: string) =>
      userService.axiosWithUserAuth.patch(`user/impersonate/${companyId}`),
    onSuccess: () => {
      if (user) {
        clearLocalStorageTableState(user.id);
      }

      if (window.location.pathname === "/") {
        window.location.reload();
      } else {
        window.location.assign("/");
      }
    },
  });
};
