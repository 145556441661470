import { uploadCsv, uploadTranslationCsv } from "@/app/api/screeners";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { downloadBlob } from "@/utils/helpers";

export const useCsvUploadQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (file: File) => uploadCsv(file),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["csv-upload"] });
    },
    onError: (error) => {
      console.error("Error uploading CSV:", error);
    },
    onSettled: (data) => {
      if (data) {
        const blob = new Blob([data], { type: "text/csv" });
        downloadBlob(blob, "uploaded_csv_result.csv");
      }
    },
  });
};

export const useCsvUploadTranslationQuery = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (file: File) => uploadTranslationCsv(file),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["csv-upload-translation"] });
    },
    onError: (error) => {
      console.error("Error uploading translation CSV:", error);
    },
  });
};
