import { Loader } from "@mantine/core";
import {
  type ColumnDef,
  type ColumnFiltersState,
  type ColumnPinningState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";

import { useUserLocalStorageState } from "@/hooks/use-user-local-storage-state.hook";
import { DateRangeOptions } from "@/types/date-range-filter";
import { Nullable } from "@/types/utils";
import {
  addOrReplaceFilterById,
  getDateRangeFromOption,
} from "@/utils/filters";
import {
  DATE_RANGE_LOCAL_STORAGE_BASE_KEY,
  FILTERS_LOCAL_STORAGE_BASE_KEY,
  LAST_SUBMISSION_DATE_RANGE_LOCAL_STORAGE_BASE_KEY,
  SORTING_LOCAL_STORAGE_BASE_KEY,
} from "@/utils/local-storage";

export interface UseTableHelperProps<TableData> {
  columns?: ColumnDef<TableData>[];
  columnPinning: ColumnPinningState;
  defaultSortingColumn?: string;
  handleSearch(searchValue: string, originalData: TableData[]): TableData[];
  isLoading: boolean;
  localStoragePrefix?: Nullable<string>;
  search: string;
  tableData?: TableData[];
}

export const useTableHelper = <TableModel,>({
  columns = [],
  columnPinning,
  defaultSortingColumn = "createdAt",
  handleSearch,
  isLoading,
  localStoragePrefix = null,
  search,
  tableData = [],
}: UseTableHelperProps<TableModel>) => {
  const withLocalStorage = !!localStoragePrefix;
  const [data, setData] = useState<TableModel[]>(tableData);
  const [sorting, setSorting] = useUserLocalStorageState<SortingState>(
    `${localStoragePrefix}${SORTING_LOCAL_STORAGE_BASE_KEY}`,
    [{ id: defaultSortingColumn, desc: true }],
    withLocalStorage,
  );
  const [
    createdAtDateRange,
    setCreatedAtDateRange,
    createdAtDateRangeInitialized,
  ] = useUserLocalStorageState<Nullable<DateRangeOptions>>(
    `${localStoragePrefix}${DATE_RANGE_LOCAL_STORAGE_BASE_KEY}`,
    null,
    withLocalStorage,
  );
  const [
    lastSubmissionDateRange,
    setLastSubmissionDateRange,
    lastSubmissionDateRangeInitialized,
  ] = useUserLocalStorageState<Nullable<DateRangeOptions>>(
    `${localStoragePrefix}${LAST_SUBMISSION_DATE_RANGE_LOCAL_STORAGE_BASE_KEY}`,
    null,
    withLocalStorage,
  );
  const [filters, setFilters, filtersInitialized] =
    useUserLocalStorageState<ColumnFiltersState>(
      `${localStoragePrefix}${FILTERS_LOCAL_STORAGE_BASE_KEY}`,
      [],
      withLocalStorage,
    );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const setDateRange = useCallback(
    (
      dateRange: Nullable<DateRangeOptions>,
      setter = setCreatedAtDateRange,
      columnId = "createdAt",
    ) => {
      setter(dateRange);
      const newDateRange = getDateRangeFromOption(dateRange);

      // Handle clearing the date range filter
      if (!newDateRange.after && !newDateRange.before) {
        return setFilters((filters) =>
          filters.filter(({ id }) => id !== columnId),
        );
      }

      return addOrReplaceFilterById(columnId, newDateRange, setFilters);
    },
    [setCreatedAtDateRange, setFilters],
  );
  const externalSetLastSubmissionDateRange = useCallback(
    (dateRange: Nullable<DateRangeOptions>) =>
      setDateRange(dateRange, setLastSubmissionDateRange, "lastSubmissionDate"),
    [setDateRange, setLastSubmissionDateRange],
  );
  const clearAllFilters = useCallback(() => {
    setFilters([]);
    setCreatedAtDateRange(null);
    setLastSubmissionDateRange(null);
  }, [setCreatedAtDateRange, setFilters, setLastSubmissionDateRange]);

  const table = useReactTable<TableModel>({
    columns,
    data,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange(updateOrValue) {
      setSorting(updateOrValue);
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
    },
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setFilters,
    state: {
      sorting,
      pagination,
      columnFilters: filters,
      columnPinning,
    },
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetPageIndex: filters.length > 0,
  });
  const loadingFallback = isLoading ? (
    <div className="w-full h-dvh flex justify-center items-center">
      <Loader color="#88D8F1" />
    </div>
  ) : null;

  const updateDateFilter = useCallback(
    (
      range: Nullable<DateRangeOptions>,
      internalSetter = setCreatedAtDateRange,
      columnId = "createdAt",
    ) => {
      // If a user is logging in with a saved date range, ensure that the actual
      // `createdAt` filter is updated with respect to the current date.
      if (range && range !== DateRangeOptions.CustomRange) {
        return setDateRange(range, internalSetter, columnId);
      }

      // If a user has gotten into a state where the date range is unset but
      // there are active date filters default the date range to custom.
      if (range === null && filters.some(({ id }) => id === columnId)) {
        internalSetter(DateRangeOptions.CustomRange);
      }
    },
    [filters, setCreatedAtDateRange, setDateRange],
  );

  // These effects initialize the two date range filters to ensure they maintain
  // their relative dates between sessions
  useEffect(() => {
    if (createdAtDateRangeInitialized && filtersInitialized) {
      updateDateFilter(createdAtDateRange);
    }
    // We only want this hook to fire when first initializing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdAtDateRangeInitialized, filtersInitialized]);
  useEffect(() => {
    if (lastSubmissionDateRangeInitialized && filtersInitialized) {
      updateDateFilter(
        lastSubmissionDateRange,
        setLastSubmissionDateRange,
        "lastSubmissionDate",
      );
    }
    // We only want this hook to fire when first initializing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersInitialized, lastSubmissionDateRangeInitialized]);

  useEffect(() => {
    setData(handleSearch(search, tableData));
  }, [handleSearch, search, tableData]);

  return {
    clearAllFilters,
    dateRange: createdAtDateRange,
    filters,
    filtersInitialized,
    lastSubmissionDateRange,
    loadingFallback,
    pagination,
    setDateRange,
    setFilters,
    setLastSubmissionDateRange: externalSetLastSubmissionDateRange,
    setPagination,
    table,
  };
};
