import { ColumnFilter, type ColumnFiltersState } from "@tanstack/react-table";
import omit from "lodash/omit";
import { Dispatch, SetStateAction, useMemo } from "react";

import { Maybe } from "@/types/utils";

interface SubFilter<T = unknown> {
  [subFilter: string]: T;
}

export interface Filter<T extends SubFilter = SubFilter> extends ColumnFilter {
  id: string;
  value: T;
}

export type DefaultFilter<ValueType> = { defaultFilter: ValueType };

// The most common usage of the useFilterState hook is with the subfilter
// `defaultFilter` containing an array of primitives. This function serves as a
// wrapper around useFilterState's update function to abstract away dealing with
// the other values in the array. For example:
/*
 *  const [filterValue, updateFilter] = useFilterState<Maybe<string[]>>(
 *   filters,
 *   setFilters,
 *   id,
 * );
 * console.log(filterValue); // ["a", "b", "c"];
 *
 * const onFilterUpdate = handleSimpleArrayFilterChange(
 *   updateFilter,
 *   filterValue,
 *   trackFilter
 * );
 *
 * <button onClick={() => onFilterUpdate("b")} />
 *
 * console.log(filterValue); // ["a", "c"];
 * */
export const handleSimpleArrayFilterChange = <T>(
  updateFilter: (newValue: Maybe<T[]>) => void,
  filterValue?: T[],
  trackFilter?: (value: T) => void,
) => {
  return (value: T) => {
    if (filterValue?.includes(value)) {
      const updatedFilters = filterValue.filter((v) => v !== value);
      return updateFilter(
        updatedFilters.length > 0 ? updatedFilters : undefined,
      );
    }

    trackFilter?.(value);

    if (filterValue) {
      return updateFilter([...filterValue, value]);
    }

    updateFilter([value]);
  };
};

export const useFilterState = <FilterValue>(
  filters: Filter[],
  setFilters: Dispatch<SetStateAction<ColumnFiltersState>>,
  id: string,
  subFilter = "defaultFilter",
) => {
  const [filter, value, index] = useMemo(() => {
    const index = filters.findIndex((filter) => filter.id === id);
    const filter = filters[index] as Maybe<Filter>;

    return [
      filter,
      filter?.value?.[subFilter] as Maybe<FilterValue>,
      index,
    ] as const;
  }, [filters, id, subFilter]);

  const updateFilter = (newValue: FilterValue) => {
    const otherSubFilters = omit(filter?.value ?? {}, subFilter);

    // The value has been updated and the filter already exists
    if (newValue && filter) {
      return setFilters((filters) =>
        filters.toSpliced(index, 1, {
          id,
          value: {
            ...otherSubFilters,
            [subFilter]: newValue,
          },
        }),
      );
    }

    // The filter does not yet exist and will be given the new value
    if (newValue) {
      return setFilters((filters) => [
        ...filters,
        { id, value: { [subFilter]: newValue } },
      ]);
    }

    // The filter does exist, and we need to clear this subfilter while leaving others intact
    if (filter && Object.keys(otherSubFilters).length > 0) {
      return setFilters((filters) =>
        filters.toSpliced(index, 1, { id, value: otherSubFilters }),
      );
    }

    // The filter exists, but this was the only subfilter. Remove it.
    if (filter) {
      return setFilters((filters) => filters.filter((f) => f.id !== id));
    }
  };

  return [value, updateFilter] as const;
};
