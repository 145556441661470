import { Combobox, Text } from "@mantine/core";

import {
  DateRangeFilterOptions,
  type DateRangeOptions,
} from "@/types/date-range-filter";

interface DateRangeFilterOptionProps extends DateRangeFilterOptions {
  onClick(value: DateRangeOptions): void;
}

export const DateRangeFilterOption = ({
  helperText,
  onClick,
  value,
}: DateRangeFilterOptionProps) => {
  return (
    <Combobox.Option
      className="first:pt-0 last:pb-0 py-1.5"
      key={value}
      onClick={() => onClick(value)}
      value={value}
    >
      <Text fw={400} size="sm">
        {value}
      </Text>
      <Text c="#999999" size="xs">
        {helperText}
      </Text>
    </Combobox.Option>
  );
};
