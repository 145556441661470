export const FILTER_VERSION = "4";
const FILTER_VERSION_LOCAL_STORAGE_BASE_KEY = "SCREENER_FILTERS_VERSION";

export const ALL_SCREENER_PREFIX = "ALL_SCREENERS_TABLE_";
export const SCREENER_LOCATION_PREFIX = "SCREENER_LOCATIONS_TABLE_";

export const DATE_RANGE_LOCAL_STORAGE_BASE_KEY = "DATE_RANGE";
export const LAST_SUBMISSION_DATE_RANGE_LOCAL_STORAGE_BASE_KEY =
  "LAST_SUBMISSION_DATE_RANGE";
export const FILTERS_LOCAL_STORAGE_BASE_KEY = "FILTERS";
export const SEARCH_LOCAL_STORAGE_BASE_KEY = "SEARCH";
export const SORTING_LOCAL_STORAGE_BASE_KEY = "SORTING";
const LOCAL_STORAGE_TABLE_KEYS = [
  DATE_RANGE_LOCAL_STORAGE_BASE_KEY,
  FILTERS_LOCAL_STORAGE_BASE_KEY,
  SEARCH_LOCAL_STORAGE_BASE_KEY,
  SORTING_LOCAL_STORAGE_BASE_KEY,
] as const;

export const makeLocalStorageKeyByUser = (key: string, userId = "") =>
  `${key}_${userId}`;

export const filterVersionLocalStorageKey = (userId: string) =>
  makeLocalStorageKeyByUser(FILTER_VERSION_LOCAL_STORAGE_BASE_KEY, userId);

export const clearLocalStorageTableState = (userId: string) =>
  LOCAL_STORAGE_TABLE_KEYS.forEach((key) => {
    localStorage.removeItem(
      makeLocalStorageKeyByUser(`${ALL_SCREENER_PREFIX}${key}`, userId),
    );
    localStorage.removeItem(
      makeLocalStorageKeyByUser(`${SCREENER_LOCATION_PREFIX}${key}`, userId),
    );
  });
